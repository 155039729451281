<template>
  <div class="history-title ">
    <template v-for="(item, index) in mapTexts" :key="item.path">
      <span
        class="path-name"
        :class="[item.isCurrt && 'active']"
        @click="!item.isCurrt && router.push(item.path)"
        >{{ item.text }}</span
      >
      <span class="separate" v-if="index !== mapTexts.length - 1">/</span>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useRouteStoreSetup, useUserStoreSetup } from "@/stores";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

type Historys = { text: string; path: string; isCurrt: boolean };
const routeStoreSetup = useRouteStoreSetup();
const userStoreSetup = useUserStoreSetup();
const route = useRoute();
const router = useRouter();

const mapTexts = computed<Historys[]>(() =>
  routeStoreSetup.routePathList.map<Historys>((item, index) => {
    return {
      text:
        userStoreSetup.personalUrlMap?.get(item)?.fullName ||
        (route.meta.fullName as string),
      path: item,
      isCurrt: route.path === item,
    };
  })
);
</script>

<style lang="scss" scoped>
.history-title {
  display: flex;
  height: 45px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.separate {
  margin: 0px 8px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969799;
}
.path-name {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969799;
  &.active {
    cursor: default;
    color: #323233;
  }
}
</style>
