import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from 'vue-router';
import { useRouteStoreSetup, useUserStoreSetup, useUserInfoSetup } from '@/stores';
import { LocakStorageKeyType } from './../utils/localStorageSaveKey';
import HistoryTitle from '@/components/pageHistory.vue';
import Xx from '../views/xx.vue';
// const HomeView = () => h('div', 'HomePage')

/**
 * 路由守卫
 */
export class ProxyRouter {
  router: Router;

  constructor(routes: RouteRecordRaw[]) {
    //   初始化路由
    this.router = createRouter({
      history: createWebHistory(import.meta.env.VITE_BASE_ROUTE),
      routes,
    });
    // 塞入页面拦截
    this.router.beforeEach(this.beforeEach.bind(this));
    this.router.afterEach(this.afterEach.bind(this));
  }

  /**
   * 页面跳转前的拦截
   */
  async beforeEach(to: RouteLocationNormalized, form: RouteLocationNormalized, next: NavigationGuardNext) {
    const userStoreSetup = useUserStoreSetup();
    const { router } = this;
    const useInfoSetup =  useUserInfoSetup()
    if(to.meta.needLogin) { 
		if(localStorage.getItem(LocakStorageKeyType.Token)){
			if(!useInfoSetup.userInfo.phoneNumber) {
				try {
					await useInfoSetup.getUserInfo();
					next()
				} catch (a) {
					localStorage.removeItem(LocakStorageKeyType.Token)
					next({
						path: '/register/login'
					})
				}
			} else {
				next()
			}
		} else {
			next({
				path: '/register/login'
			})
		}
    }  else {
		next()
    }
    
  }

  afterEach(to: RouteLocationNormalized) {
    const routeStoreSetup = useRouteStoreSetup();
    routeStoreSetup.setRoutePathList(to.path);
  }
}
