import { RouteRecordRaw } from 'vue-router';
import { ProxyRouter } from './proxy';

import History from '@/components/pageHistory.vue';

// meta 中 user的tab表示需要等陆
const routes: RouteRecordRaw[] = [
	{
		path: '/',
		redirect: '/register/rough',
	},
	{
		path: '/404',
		name: 'Not_Fount',
		component: () => import('@/views/404.vue'),
	},{
		path: '/secondary-node-template',
		name: 'SecondaryNode',
		component: () => import('@/views/secondaryNode/template/index.vue'),
		redirect: {
			name: 'secondaryNodeTemplateList',
		},
		children: [
			{
				path: '/secondary-node-template/list', 
				name: 'secondaryNodeTemplateList',
				meta: {
					fullName: '二级节点模板列表',
				},
				component: () => import('@/views/secondaryNode/template/list.vue')
			}, {
				path: '/secondary-node-template/detail', 
				name: 'secondaryNodeTemplateDetail',
				meta: {
					fullName: '二级节点模板详细',
				},
				component: () => import('@/views/secondaryNode/template/detail.vue')
			},
		]
	},
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/index.vue'),
    redirect: {
      	name: 'login',
    },
    children: [
		{
			path: '/register/rough',
			name: 'rough',
			meta: {
				fullName: '账户注册',
			},
			components: {
				history: History,
				default: () => import('@/views/register/rough/index.vue'),
			}
		}, {
			path: '/register/detail',
			name: 'detail',
			meta: {
				fullName: '账户详细注册',
				needLogin: true
			},
			components: {
				history: History,
				default: () => import('@/views/register/detail/index.vue'),
			}
		}, {
			path: '/register/result',
			name: 'result',
			meta: {
				fullName: '认证结果页面',
				needLogin: true
			},
			components: {
				history: History,
				default: () => import('@/views/register/detail/index.vue'),
			}
		}, {
			path: '/register/promote',
			name: 'promote',
			meta: {
				fullName: '公司推广',
			},
			components: {
				history: History,
				default: () => import('@/views/register/detail/index.vue'),
			}
		}, {
			path: '/register/tips',
			name: 'tips',
			meta: {
				fullName: '认证提示页面',
				needLogin: true
			},
			components: {
				history: History,
				default: () => import('@/views/register/tips.vue'),
			}
		}, {
			path: '/register/login',
			name: 'login',
			meta: {
				fullName: '登录',
			},
			components: {
				history: History,
				default: () => import('@/views/register/login.vue'),
			}
		}, {
			path: '/register/resetCode', 
			name: 'resetCode',
			meta: {
				fullName: '重置密码',
			},
			components: {
				history: History,
				default: () => import('@/views/register/resetcode.vue'),
			}
		}
    ]
  }
];

const router = new ProxyRouter(routes).router;

export default router;
